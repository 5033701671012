import { FC } from 'react'

import { Avatar } from '@mui/material'

import { FieldRenderer } from '../../FieldRenderer'
import { BaserowWorkspaceUser } from '../../lib/types'

interface RevisionBubbleProps {
  revision: any // Not typed
  commenter: { name: string; avatarUrl: string }
  currentUserName: string
  usersList: BaserowWorkspaceUser[]
  workspaceUsers: BaserowWorkspaceUser[]
}

const RevisionBubble: FC<RevisionBubbleProps> = ({
  revision,
  commenter,
  currentUserName,
  usersList,
  workspaceUsers,
}) => {
  //if the revision was made by the current user
  const isCurrentUserRevision = revision.user.name === currentUserName
  const revisionUser = usersList.find(
    (user) => user.name === revision.user.name,
  ) ?? {
    name: revision.user.name,
  }

  const justifyClass = isCurrentUserRevision ? 'justify-end' : 'justify-start'

  const linkCollaborators = (values = [], collaborators = []) => {
    return values?.map((value) => {
      const matchingCollaborator =
        collaborators?.find(
          (collaborator) => collaborator?.user_id === value?.id,
        ) ?? null

      return {
        ...value,
        name: matchingCollaborator?.name ?? value.name,
      }
    })
  }

  return (
    <div className={`flex w-full ${justifyClass}`}>
      <div className={`flex w-4/5 gap-2 ${justifyClass}`}>
        {!isCurrentUserRevision && (
          <div className="flex-shrink-0">
            <Avatar
              src={commenter?.avatarUrl}
              alt={commenter?.name}
              className="bg-gray-200 font-bold text-gray-700"
            >
              {commenter?.name
                .split(' ')
                .map((part) => part[0])
                .slice(0, 2)
                .join('')}
            </Avatar>
          </div>
        )}

        <div
          className={`flex flex-col rounded-lg border-2 p-2 ${isCurrentUserRevision ? 'border-indigo-300' : 'border-gray-200'}`}
        >
          <div className="flex justify-between gap-4">
            <p className="text-xs text-gray-500">
              <strong>
                {isCurrentUserRevision ? 'You' : revisionUser?.name}
              </strong>
            </p>
            <p className="text-xs text-gray-500">
              {new Date(revision.timestamp).toLocaleString('en-AU')}
            </p>
          </div>
          <div className="mt-2 flex flex-col">
            {revision.changes.map((change, index) => {
              return (
                <div key={index} className="flex flex-col">
                  <p className="text-sm font-semibold text-gray-600">
                    {change.fieldName}
                  </p>
                  {(change?.before || change?.before === 0) && (
                    <span className="mt-1 rounded bg-red-100 p-1 text-xs text-gray-500">
                      <FieldRenderer
                        field={change.field}
                        record={{
                          value: change.beforeRecord.value,
                          getCellValue: (_) =>
                            change?.field?.type === 'multiple_collaborators'
                              ? linkCollaborators(
                                  change.beforeRecord.value,
                                  workspaceUsers,
                                )
                              : change.beforeRecord.value,
                          getCellValueAsString: (_) => change.before,
                        }}
                        isName={false}
                      />
                    </span>
                  )}
                  {(change?.after || change?.after === 0) && (
                    <span
                      className="mt-0.5 rounded bg-green-100 p-1 text-xs text-black"
                      data-testid={`hubdash-after-value-${change.after}`}
                    >
                      <FieldRenderer
                        field={change.field}
                        record={{
                          value: change.afterRecord.value,
                          getCellValue: (_) =>
                            change?.field?.type === 'multiple_collaborators'
                              ? linkCollaborators(
                                  change.afterRecord.value,
                                  workspaceUsers,
                                )
                              : change.afterRecord.value,
                          getCellValueAsString: (_) => change.after,
                        }}
                        isName={false}
                      />
                    </span>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RevisionBubble
