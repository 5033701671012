import React, { useCallback, useEffect, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import type {
  SendContactToBaserowMutation,
  SendContactToBaserowMutationVariables,
  SentimentStatusOptions,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'

import { QUERY as FindSentimentAnalysisQuery } from '../SentimentAnalysisCell'
import {
  SentimentAnalysisStatusOptionsDisplayMap,
  SentimentStatusSelectOptions,
} from '../SentimentAnalysisHelper'
import { SEND_CONTACT_TO_BASEROW } from '../SentimentAnalysisQueries'
import { QUERY as SentimentEmailsQuery } from '../SentimentEmailsCell'

interface SendToHubdashConfirmDialogProps {
  id: number | null
  openConfirmDialog: boolean
  setOpenConfirmDialog: (open: boolean) => void
  setLoadingStatus: (id: number, status: boolean) => void
}

const SendToHubdashConfirmDialog = (props: SendToHubdashConfirmDialogProps) => {
  const { id, openConfirmDialog, setOpenConfirmDialog, setLoadingStatus } =
    props
  const [comment, setComment] = useState<string>('')
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [status, setStatus] = useState<SentimentStatusSelectOptions>(
    SentimentStatusSelectOptions.IN_REVIEW,
  )

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setComment('')
    setStatus(SentimentStatusSelectOptions.IN_REVIEW)
    setOpenConfirmDialog(false)
  }

  const [sendContactToBaserow] = useMutation<
    SendContactToBaserowMutation,
    SendContactToBaserowMutationVariables
  >(SEND_CONTACT_TO_BASEROW, {
    onCompleted: () => {
      toast.success('Contact sent to HubDash')
      setLoadingStatus(id, false)
    },
    onError: (error) => {
      toast.error(error?.message || 'An unexpected error occurred.', {
        duration: 2000,
        className: 'flex-column',
      })
      setLoadingStatus(id, false)
    },
    awaitRefetchQueries: true,
    refetchQueries: [SentimentEmailsQuery, FindSentimentAnalysisQuery],
  })

  const handleSendContactToBaserow = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      const input = {
        landlordId: id,
        clientStatus: status as SentimentStatusOptions,
        comment: comment,
      }

      sendContactToBaserow({
        variables: { input },
      })
    },
    [sendContactToBaserow, id, status, comment],
  )

  useEffect(() => {
    if (comment.length > 3) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [comment])

  return (
    <>
      <Dialog
        open={openConfirmDialog}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>Confirm Send Contact to HubDash</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-sm">
            This will update the contact in HubDash. <br />
            Please add any comments and select a status.
          </DialogContentText>
          <div className="flex flex-col gap-4 pt-4">
            <TextField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              required
              multiline
              rows={4}
              margin="dense"
              id="comments"
              name="comments"
              label="Comments"
              type="text"
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
            <Select
              labelId="status"
              id="status"
              fullWidth
              placeholder="Select a Status"
              data-testid="sa-send-to-hubdash-status"
              value={
                status === SentimentStatusSelectOptions.ALL
                  ? SentimentStatusSelectOptions.IN_REVIEW
                  : status
              }
              onChange={(event) =>
                setStatus(event.target.value as SentimentStatusSelectOptions)
              }
            >
              {Object.entries(SentimentAnalysisStatusOptionsDisplayMap)
                .filter(([key]) => key !== SentimentStatusSelectOptions.ALL)
                .map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={false}
            onClick={handleClose}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            fullWidth={false}
            disabled={disableSubmit}
            onClick={(event) => {
              setLoadingStatus(id, true)
              handleClose(event)
              handleSendContactToBaserow(event)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SendToHubdashConfirmDialog
