import React, { FC } from 'react'

import 'reactflow/dist/style.css'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import { toPng } from 'html-to-image'

import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import useAnalytics from 'src/lib/hooks/useAnalytics'

export interface ReactMapDownloadButtonProps {
  mapName: string
  legendRef: React.RefObject<HTMLDivElement>
  deselectNodes: () => void
  reactFlow: any
}

const ReactMapDownloadButton: FC<ReactMapDownloadButtonProps> = ({
  mapName,
  legendRef,
  deselectNodes,
  reactFlow,
}) => {
  const [loading, setLoading] = React.useState(false)
  const { trackEvent } = useAnalytics()

  const downloadImage = (dataUrl) => {
    const a = document.createElement('a')
    a.setAttribute('download', `${mapName}.png`)
    a.setAttribute('href', dataUrl)
    a.click()
  }

  const DownloadButton = () => {
    setLoading(true)

    const originalCanvas = document.querySelector('.react-flow__renderer')

    // Create and style the title element
    const titleElement = document.createElement('div')
    titleElement.textContent = mapName // Use the mapName prop as the title
    titleElement.style.position = 'absolute'
    titleElement.style.left = '50%'
    titleElement.style.top = '10px'
    titleElement.style.transform = 'translateX(-50%)'
    titleElement.style.fontWeight = 'bold'
    titleElement.style.fontSize = '40px'
    titleElement.style.color = '#333'
    titleElement.style.textAlign = 'center'

    const legend = legendRef.current.cloneNode(true) as HTMLElement

    legend.style.position = 'absolute'
    legend.style.left = '20px'
    legend.style.top = '20px'
    legend.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.1)'
    legend.style.backgroundColor = '#fff'
    legend.style.borderRadius = '4px'
    legend.style.padding = '10px'
    legend.style.boxSizing = 'border-box'

    originalCanvas.appendChild(titleElement)
    originalCanvas.appendChild(legend)

    toPng(originalCanvas as HTMLElement, {
      backgroundColor: 'white',
      width: originalCanvas.clientWidth,
      height: originalCanvas.clientHeight,
      style: {
        width: `${originalCanvas.clientWidth}px`,
        height: `${originalCanvas.clientHeight}px`,
        transformOrigin: 'top left',
      },
      pixelRatio: 3,
    })
      .then(downloadImage)
      .then(() => {
        setLoading(false)
        toast.success('Downloading...')
        originalCanvas.removeChild(legend) // Clean up
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error creating high-resolution image', err)
        originalCanvas.removeChild(legend) // Clean up
      })
  }

  return (
    <Tooltip title={'Download Map'}>
      <div>
        <Button
          fullWidth={false}
          variant="text"
          className="min-w-[0] rounded-full p-2 text-gray-500"
          loading={loading}
          onClick={() => {
            deselectNodes()
            reactFlow.fitView()
            DownloadButton()
            trackEvent('Process Maps', 'Download Map')
          }}
        >
          <ArrowDownTrayIcon className="h-5 w-5" />
        </Button>
      </div>
    </Tooltip>
  )
}
export default ReactMapDownloadButton
