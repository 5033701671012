import React, {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  ArrowTopRightOnSquareIcon,
  Bars3BottomRightIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid'
import { Divider, Menu, MenuItem } from '@mui/material'
import {
  DeleteHubDashLayoutToHubDashCard,
  DeleteHubDashLayoutToHubDashCardVariables,
  MembershipRole,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import CardContentChartBar from 'src/components/HubDash/CardContent/CardContentChartBar'
import CardContentChartPie from 'src/components/HubDash/CardContent/CardContentChartPie'
import CardContentCounter from 'src/components/HubDash/CardContent/CardContentCounter'
import CardExpand from 'src/components/HubDash/CardExpand/CardExpand'
import { QUERY } from 'src/components/HubDash/HubDashLayoutListCell/HubDashLayoutListCell'
import { useBaserowViewSocket } from 'src/components/HubDash/lib/baserow/useBaserowSocket'
import Loading, {
  default as LoadingSpinner,
} from 'src/components/Library/Loading'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useHubDashStore from 'src/lib/stores/hubDashStore'
import { useAuth } from 'src/Providers'

import { CardType } from '../lib/enums'
import type { HubDashCardType } from '../lib/types'

interface HubDashCardProps {
  card: HubDashCardType
  baserowData: any // not typed yet
  setSettingsCard: Dispatch<SetStateAction<HubDashCardType>>
  setCardSettingsOpen: Dispatch<SetStateAction<boolean>>
  isPreview: boolean
  triggerResize?: number
}

const HubDashCard: FC<HubDashCardProps> = ({
  card,
  baserowData,
  setSettingsCard,
  setCardSettingsOpen,
  isPreview,
  triggerResize = null,
}) => {
  const { hasRole } = useAuth()
  const adminRoles: MembershipRole[] = ['ADMIN', 'OWNER']
  const isAdmin = hasRole(adminRoles)

  const hasErrors = baserowData?.errors?.length > 0

  const [isUnlocked, selectedUsers] = useHubDashStore((state) => [
    state.isUnlocked,
    state.selectedUsers,
  ])

  const { trackEvent } = useAnalytics()

  const [cardExpandOpen, setCardExpandOpen] = useState(false)

  useEffect(() => {
    if (cardExpandOpen) {
      window.Intercom('update', {
        alignment: 'left',
      })
    } else {
      window.Intercom('update', {
        alignment: 'right',
      })
    }
  }, [cardExpandOpen])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }

  const cardData = card?.cardData

  const cardSocket = useBaserowViewSocket({
    baserowData: baserowData,
    tableData: baserowData?.table,
    viewData: baserowData?.view,
  })

  if (cardSocket?.records) {
    let filteredRecords = cardSocket.records
    if (card?.cardSettings?.filters?.user && selectedUsers.length > 0) {
      const userFilter = card?.cardSettings?.filters?.user
      filteredRecords = cardSocket.records.filter((record) => {
        const recordFilterValue = record.getCellValueAsString(userFilter.name)
        if (Array.isArray(recordFilterValue)) {
          return recordFilterValue.some((value) => {
            if (
              typeof value === 'object' &&
              value !== null &&
              'value' in value
            ) {
              return selectedUsers.includes(value.value)
            } else {
              if (Array.isArray(value)) {
                return value.some((nestedValue) => {
                  if (
                    typeof nestedValue === 'object' &&
                    nestedValue !== null &&
                    'value' in nestedValue
                  ) {
                    return selectedUsers.includes(nestedValue.value)
                  } else {
                    return selectedUsers.includes(nestedValue)
                  }
                })
              } else {
                return selectedUsers.includes(value)
              }
            }
          })
        } else {
          return selectedUsers.includes(recordFilterValue)
        }
      })
    }
    cardSocket.records = filteredRecords
    cardSocket.activeRecords = filteredRecords.filter(
      (record) => record?.decorators?.left_border_color.length > 0,
    )
    cardSocket.inactiveRecords = filteredRecords.filter(
      (record) => record?.decorators?.left_border_color.length === 0,
    )

    if (cardSocket?.activeRecords?.length > 0) {
      // Only send the intercom tour if the user has at least 1 card with active records
      window.Intercom('update', {
        user_has_any_hubdash_card_with_active_records: true,
      })
    }
  }

  const CardContentWarning = ({ message }) => {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-2 overflow-clip text-center text-sm">
        <ExclamationCircleIcon className="h-10 w-10 text-orange-400" />
        <p className="text-orange-400">{message}</p>
      </div>
    )
  }

  let cardContent = null

  const cardIsChartType = [CardType.CHART_BAR, CardType.CHART_PIE].includes(
    card?.type as CardType,
  )

  if (!baserowData?.records) {
    cardContent = <Loading />
  }

  if (!cardData?.base) {
    cardContent = <CardContentWarning message="No Base selected" />
  } else if (!cardData?.table) {
    cardContent = <CardContentWarning message="No Table selected" />
  } else if (!cardData?.view) {
    cardContent = <CardContentWarning message="No View selected" />
  } else if (cardIsChartType && !card?.cardSettings?.chartSettings?.category) {
    cardContent = <CardContentWarning message="No Category selected" />
  }

  // If connected and no errors have been set - Try find category field
  if (
    cardSocket?.connectionStatus &&
    cardSocket?.connectionStatus !== 'Connecting' &&
    !cardContent
  ) {
    if (
      cardIsChartType &&
      cardSocket?.table &&
      !cardSocket?.table?.fields?.find(
        (field) =>
          field.name === card?.cardSettings?.chartSettings?.category?.name,
      )
    ) {
      cardContent = (
        <CardContentWarning
          message={`Category field "${card?.cardSettings?.chartSettings?.category?.name}" (id: ${card?.cardSettings?.chartSettings?.category?.id}) was not found in table "${cardData?.table?.name}" (id: ${cardData?.table?.id})`}
        />
      )
    }
  }

  if (baserowData?.errors?.length > 0) {
    // find if there is a table error
    const tableError = baserowData?.errors.find(
      (error) => error.entity === 'table',
    )
    if (tableError) {
      cardContent = <CardContentWarning message={tableError.message} />
    } else {
      // find if there is a view error
      const viewError = baserowData?.errors.find(
        (error) => error.entity === 'view',
      )
      if (viewError) {
        cardContent = <CardContentWarning message={viewError.message} />
      } else {
        // show the first error
        cardContent = (
          <CardContentWarning message={baserowData?.errors[0]?.message} />
        )
      }
    }
  }

  if (!cardContent) {
    switch (card.type) {
      case CardType.COUNTER:
        cardContent = (
          <CardContentCounter
            cardSocket={cardSocket}
            card={card}
            triggerResize={triggerResize}
          />
        )
        break
      case CardType.CHART_BAR:
        cardContent = (
          <CardContentChartBar cardSocket={cardSocket} card={card} />
        )
        break
      case CardType.CHART_PIE:
        cardContent = (
          <CardContentChartPie cardSocket={cardSocket} card={card} />
        )
        break
      default:
        cardContent = <div>No Card Type</div>
    }
  }

  const DELETE_HUBDASH_LAYOUT_TO_HUBDASH_CARD = gql`
    mutation DeleteHubDashLayoutToHubDashCard($id: Int!) {
      deleteHubDashLayoutToHubDashCard(id: $id) {
        id
      }
    }
  `

  const [deleteLayoutToCard, { loading: deleteLayoutToCardLoading }] =
    useMutation<
      DeleteHubDashLayoutToHubDashCard,
      DeleteHubDashLayoutToHubDashCardVariables
    >(DELETE_HUBDASH_LAYOUT_TO_HUBDASH_CARD, {
      onCompleted: () => {
        setAnchorEl(null)
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    })

  const handleRemove = (card) => {
    if (isUnlocked) {
      deleteLayoutToCard({
        variables: {
          id: card.relationId,
        },
      })
    }
  }

  return (
    <>
      <div
        className={'relative h-full w-full'}
        // only send the intercom tour if the use user has at least 1 card with active records
        data-intercom-target={`hubdash-card-with-${cardSocket?.activeRecords?.length > 0 ? '' : 'no'}-active-records`}
        data-testid={`hubdash-card-${card.name}`}
        role="button" // make this div behave like a button
        tabIndex={0} // make this div focusable
        onClick={() => {
          if (!isUnlocked && !hasErrors && !isPreview) {
            setCardExpandOpen(true)
            trackEvent('HubDash', 'Expand Card', {
              cardName: card.name,
            })
          }
        }}
        onKeyDown={(event) => {
          // check if the key pressed was 'Enter' or 'Space'
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            if (!isUnlocked && !hasErrors && !isPreview) {
              setCardExpandOpen(true)
              trackEvent('HubDash', 'Expand Card', {
                cardName: card.name,
              })
            }
          }
        }}
      >
        <div
          className={`border-corner flex h-full w-full flex-col rounded-lg border-2 border-gray-200 bg-white p-1 hover:shadow-md ${
            isUnlocked && !isPreview
              ? 'cursor-grab opacity-90'
              : 'cursor-pointer'
          }`}
          style={{
            backgroundColor:
              card?.cardSettings?.appearance?.backgroundColor?.hex,
          }}
        >
          <div className="flex w-full justify-between">
            <div>
              <p className="line-clamp-1 pl-1 pt-1 text-gray-500">
                {card.name}
              </p>
              {card.type !== CardType.HEADING && (
                <p className="line-clamp-1 pl-1 pt-1 text-sm text-gray-400">
                  {card.description}
                </p>
              )}
            </div>

            {!isPreview && (
              <div id="card-menu-button">
                <button
                  className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg hover:bg-gray-100 ${
                    open ? 'bg-gray-200' : ''
                  }`}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => handleClick(e)}
                  onMouseDown={(e) => handleClick(e)}
                >
                  <Bars3BottomRightIcon className="h-6 w-6 text-gray-500" />
                </button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => handleClose(e)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <MenuItem
                    className="flex gap-1 text-sm text-gray-500"
                    onClick={(
                      e: React.MouseEvent<HTMLLIElement, MouseEvent>,
                    ) => {
                      openWindowWithBlockCheck(
                        `/app/baselink/database/${cardData?.base?.id}/table/${cardData?.table?.id}`,
                        '_blank',
                      )
                      handleClose(e)
                      trackEvent('HubDash', 'Click On Card Linked Base')
                    }}
                  >
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-500" />
                    <p>
                      {'Base:'}&nbsp; {cardData?.base.name} -{' '}
                      {cardData?.base?.id}
                    </p>
                  </MenuItem>
                  <MenuItem
                    className="flex gap-1 text-sm text-gray-500"
                    onClick={(e) => {
                      openWindowWithBlockCheck(
                        `/app/baselink/database/${cardData?.base?.id}/table/${cardData?.table?.id}`,
                        '_blank',
                      )
                      handleClose(e)
                      trackEvent('HubDash', 'Click On Card Linked Table')
                    }}
                  >
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-500" />
                    <p>
                      Table: {cardData?.table?.name} - {cardData?.table?.id}
                    </p>
                  </MenuItem>
                  <MenuItem
                    className="flex gap-1 text-sm text-gray-500"
                    onClick={(e) => {
                      openWindowWithBlockCheck(
                        `/app/baselink/database/${cardData?.base?.id}/table/${cardData?.table?.id}/${cardData?.view?.id}`,
                        '_blank',
                      )
                      handleClose(e)
                      trackEvent('HubDash', 'Click On Card Linked View')
                    }}
                  >
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-500" />
                    <p>
                      {'View:'}&nbsp; {cardData?.view?.name} -{' '}
                      {cardData?.view?.id}
                    </p>
                  </MenuItem>
                  <Divider hidden={!isAdmin} />
                  <MenuItem
                    onClick={(event) => {
                      setSettingsCard(card)
                      setCardSettingsOpen(true)
                      handleClose(event)
                      trackEvent('HubDash', 'open card settings')
                    }}
                    className={`flex gap-1 text-sm ${!isAdmin ? 'hidden' : ''} `}
                  >
                    <PencilSquareIcon className="h-4 w-4 text-gray-500" />
                    <p>Edit</p>
                  </MenuItem>
                  <MenuItem
                    className={`flex items-center gap-1 text-sm text-red-500 ${!isAdmin ? 'hidden' : ''}`}
                    disabled={!isUnlocked || deleteLayoutToCardLoading}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleRemove(card)
                      trackEvent('HubDash', 'Remove Card From Layout')
                    }}
                  >
                    {deleteLayoutToCardLoading && (
                      <div>
                        <LoadingSpinner
                          circularProgressProps={{ color: 'error' }}
                          size="x-small"
                        />
                      </div>
                    )}
                    {!deleteLayoutToCardLoading && (
                      <TrashIcon className="h-4 w-4 text-red-500" />
                    )}
                    <p>Remove</p>
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
          <div
            className="flex-grow overflow-hidden"
            id={card?.id?.toString() || '0'}
          >
            {cardContent}
          </div>
        </div>
      </div>
      {!hasErrors && (
        <CardExpand
          cardExpandOpen={cardExpandOpen}
          setCardExpandOpen={setCardExpandOpen}
          cardSocket={cardSocket}
          card={card}
        />
      )}
    </>
  )
}

export default HubDashCard
